import instance from "@/http/axios";
import {socket} from "@/http/socket";
import uuid from "uuid";

export const sendNotification = (from, to, receiver, type, title, description) => {
    let notificationData = new FormData();
    let notification = {
        "key": uuid.v4(),
        "from": from,
        "to": to,
        "receiver": receiver,
        "createdAt": new Date().getTime(),
        "type": type,
        "content": {
            "title": title,
            "description": description
        },
        "options": {
            "read": false,
            "hide": false
        }
    }

    notificationData.append('data', JSON.stringify(notification))

    instance.post('/user/notification', notificationData).then(response => {
        if (response.data.code === 200) socket.emit('notification', notification)
    })
}