import router from "@/router";

export const authUser = (state, payload) => {
    state.authUser.id = payload.id.$oid;
    state.authUser.token = payload.token;
    state.authUser.expireIn = payload.expireIn;
    state.authUser.loginDate = payload.loginDate;
    if (state.authUser.token !== null && state.authUser.id !== null) {
        localStorage.setItem("uid", payload.id.$oid);
        localStorage.setItem("userToken", payload.token);
        router.push("/");
    }
};

export const userInfoUpdate = (state, payload) => {
    state.authUser.name = payload.data.name;
    state.authUser.email = payload.data.email;
    state.authUser.token = payload.data.token;
    state.authUser.permissions = payload.data.permissions;
    state.authUser.loginDate = payload.data.loginDate;
    state.authUser.expireIn = payload.data.expireIn;
    state.authUser.departmentInfo = payload.data.departmentInfo;
    state.authUser.departmentSuperUser = payload.data.departmentSuperUser;
    state.authUser.notifications = typeof payload.data.notifications !== "undefined" ? payload.data.notifications.filter(v => v.options.hide === false) ?? [] : []
    state.authUser.settings = payload.data.settings ?? {}
};

export const authDestroy = state => {
    state.authUser.token = null;
    state.authUser.id = null;

    localStorage.clear();
    router.push("/auth");
};

export const notificationUpdate = (state, payload) => {
    state.authUser.notifications.push(payload)
}
