import instance from "@/http/axios";
import {message} from "ant-design-vue";
import {sendNotification} from "@/helpers/sendNotification";

const state = {
    customerInformation: null
};

const getters = {
    getCustomerInformation(state) {
        return state.customerInformation;
    }
};

const mutations = {
    updateCustomerInformation(state, payload) {
        state.customerInformation = payload;
    }
};

const actions = {
    callCustomerInformation({commit, state}, payload) {
        return instance
            .get("/customer/get/only", {
                params: {
                    oid: payload
                }
            })
            .then(response => {
                commit("updateCustomerInformation", response.data);
                return state.customerInformation !== null;
            })
            .catch(err => {
                console.log(err);
                message.error(
                    "Müşteri bilgileri alınırken bir hata oluştu! Lütfen tekrar deneyin."
                );
            });
    },
    /* eslint-disable-next-line */
    callUpdateCustomerInformation({commit}, payload) {
        let formData = new FormData();
        let agent = this.state.authUser;

        if (payload.agent.length === 0)
            formData.append("agent", JSON.stringify(agent));
        formData.append("cp_id", payload.cp_id);
        formData.append("oid", payload.oid);
        formData.append("phones", JSON.stringify(payload.phones));
        formData.append("description", payload.description);
        formData.append("name", payload.name);
        formData.append("mail", JSON.stringify(payload.mail));
        formData.append("files", JSON.stringify(payload.files));
        formData.append("catalogs", JSON.stringify(payload.catalogs));
        formData.append("taxName", payload.taxName);
        formData.append("taxNumber", payload.taxNumber);

        if (payload.addCatalog) sendNotification('system', 'department', 'accouting', 'info', 'Yeni bir katalog eklendi!', payload.name + " isimli müşteriye " + this.getters.authUserInfo.name + " tarafından yeni bir katalog eklendi")

        instance
            .post("/customer/update/withAssignedAgent", formData)
            .then(response => {
                message.success(response.data.msg, 3);
                /* eslint-disable-next-line */
            }).catch(error => {
            message.error(
                "API sunucusuna bağlanırken bir sorunla karşılaşıldı. Daha sonra tekrar dene",
                4
            );
        });
    },
    /* eslint-disable-next-line */
    callUpdateAssignedCustomerInformation({commit, state}, payload) {
        let formData = new FormData();

        formData.append("cp_id", payload.cp_id);
        formData.append("oid", payload.oid);
        formData.append("phones", JSON.stringify(payload.phones));
        formData.append("description", payload.description);
        formData.append("name", payload.name);
        formData.append("mail", JSON.stringify(payload.mail));
        formData.append("files", JSON.stringify(payload.files));
        formData.append("catalogs", JSON.stringify(payload.catalogs));
        formData.append("taxName", payload.taxName);
        formData.append("taxNumber", payload.taxNumber);

        if (payload.addCatalog) sendNotification('system', 'department', 'accouting', 'info', 'Yeni bir katalog eklendi!', payload.name + " isimli müşteriye " + this.getters.authUserInfo.name + " tarafından yeni bir katalog eklendi")

        instance
            .post("/customer/update/assignedAgent", formData)
            .then(response => {
                message.success(response.data.msg, 3);
            }).catch(error => {
            message.error(
                "API sunucusuna bağlanırken bir sorunla karşılaşıldı. Daha sonra tekrar dene",
                4
            );
        });
    }
};

export default {state, getters, mutations, actions};
