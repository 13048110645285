class SyncStorage {
    constructor(store) {
        /**
         * LocalStorage'da giriş yapılmış kullanıcı bilgileri ile store tekrardan güncellenir..
         * Yalnızca sayfa yenilenmesinde veya direkt olarak bir adrese girişte çalışır
         */

        let token = localStorage.getItem('userToken'),
            uid = localStorage.getItem('uid');

        if (token !== null && uid !== null) store.dispatch("initApp").then(response => {
            let expirationDate = (response.loginDate + +response.expireIn) * 1000; // ms
            let time = new Date().getTime(); // return ms
            response.dispatch("authExpireIn", expirationDate - time);
        }).catch(error => console.log(error));

    }
}

export default function (data) {
    return new SyncStorage(data);
}