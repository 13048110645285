export const isAuth = state => {
  return state.authUser.token !== null;
};

export const authUserInfo = state => {
  return state.authUser;
};

export const authUserToken = state => {
  return state.authUser.token;
};
