import {message} from "ant-design-vue";

const state = {
    catalogs: []
};

const getters = {
    getCatalogs(state) {
        return state.catalogs
    }
};

const mutations = {
    addCatalog(state, payload) {
        state.catalogs.push(payload)
        message.success('Katalog arşivi düzenlendi! Kayıtların kaybolmaması için müşteriyi kaydedin!', 3)
    },
    removeCatalog(state, payload) {
        state.catalogs.splice(payload, 1)
        message.success('Katalog başarıyla silindi! Kayıtların kaybolmaması için müşteriyi kaydedin!', 3)
    },
    flushCatalog(state) {
        state.catalogs = []
    }
};

const actions = {
    addCatalog({commit, state}, payload) {
        commit('addCatalog', payload)
    },
    removeCatalog({commit, state}, payload) {
        commit('removeCatalog', payload)
    },
    flushCatalog({commit, state}, payload) {
        commit('flushCatalog')
    }
};

export default {state, getters, mutations, actions};
