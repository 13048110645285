<template>
  <router-view/>
</template>

<script>
import {defineComponent} from "vue";
import instance from "@/http/axios";

export default defineComponent({
  created() {
    // this.$store.dispatch("initApp").then(response => {
    //   let expirationDate = (response.loginDate + +response.expireIn) * 1000; // ms
    //   let time = new Date().getTime(); // return ms
    //   response.dispatch("authExpireIn", expirationDate - time);
    // }).catch(error => console.log(error));

    /* Request Header */
    let userToken = localStorage.getItem("userToken");
    let uid = localStorage.getItem("uid");
    if (userToken !== null && uid !== null) {
      instance.defaults.headers = {
        Authorization: "Bearer " + userToken,
        UID: uid,
        Token: userToken
      }
    }

  }
});
</script>