import {message} from "ant-design-vue";

export const connectionStatus = (trying = false) => {
    if (!trying)
        message.loading('İnternet bağlantınızda sorun var!', 3, () => {
            message.loading('Tekrar bağlantı deneniyor...', 3, () => {
                if (navigator.onLine) message.success('Bağlantı başarılı!')
                else connectionStatus(true)
            })
        })
    else
        message.error('Bağlantı kurulamadı!', 3, () => {
            message.loading('Tekrar bağlantı deneniyor...', 3, () => {
                if (navigator.onLine) message.success('Bağlantı başarılı!')
                else connectionStatus(true)
            })
        })
}