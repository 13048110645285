import {createApp} from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import "ant-design-vue/dist/antd.css";
import router from "./router";
import store from "./store";
import CKEditor from "@ckeditor/ckeditor5-vue"

const Vue = createApp(App);

Vue.use(store)
    .use(router)
    .use(Antd)
    .use(CKEditor);

/* development mode */
// Vue.config.errorHandler = () => null;
Vue.config.warnHandler = () => null;

Vue.mount("#app");
