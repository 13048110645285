import { createStore } from "vuex";

/* Default Methods */
import * as mutations from "@/store/static/mutations";
import * as actions from "@/store/static/actions";
import * as getters from "@/store/static/getters";
/* Default Methods */

/* Imported Modules */
import customers from "@/store/modules/customers";
import catalogs from "@/store/modules/catalogs";
import syncStorage from "@/store/plugins/syncStorage";
/* Imported Modules */

export default createStore({
  state: {
    authUser: {
      id: null,
      name: null,
      token: null,
      permissions: {},
      expireIn: null,
      loginDate: null,
      departmentInfo: null,
      departmentSuperUser: null,
      notifications: [],
      settings: {}
    }
  },
  mutations,
  actions,
  getters,
  plugins: [
    syncStorage
  ],
  modules: {
    customers,
    catalogs
  }
});
