import {createRouter, createWebHistory} from "vue-router";
import store from "../store/index";
import {message} from "ant-design-vue";
import {connectionStatus} from "@/helpers/connectionStatus";

const routes = [
    {
        path: "/",
        name: "dashboard",
        breadcrumbName: "Ana Sayfa",
        component: () => import("../views/Dashboard"),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuth) {
                next();
            } else {
                next("/auth");
            }
        }
    },
    {
        path: "/auth",
        name: "auth",
        component: () => import("../views/Auth"),
        beforeEnter: (to, from, next) => {
            if (!store.getters.isAuth) {
                next();
            } else {
                next("/");
            }
        }
    },
    {
        path: "/customers",
        name: "customers",
        breadcrumbName: "Müşterilerim",
        component: () => import("../views/Customers/Home"),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuth) {
                next();
            } else {
                next("/auth");
            }
        }
    },
    {
        path: "/accouting",
        name: "accouting",
        breadcrumbName: "Müşteriler",
        component: () => import("../views/Accouting/Home"),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuth) {
                next();
            } else {
                next("/auth");
            }
        }
    },
    {
        path: "/support",
        name: "support",
        breadcrumbName: "Destek",
        component: () => import("../views/Support/Home"),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuth) {
                next();
            } else {
                next("/auth");
            }
        }
    },
    {
        path: "/settings",
        name: "settings",
        breadcrumbName: "Ayarlar",
        component: () => import("../views/Settings/Home"),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuth) {
                next();
            } else {
                next("/auth");
            }
        }
    },
    {
        path: "/users",
        name: "users",
        breadcrumbName: "Kullanıcılar",
        component: () => import("../views/Users/Home"),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuth) {
                next();
            } else {
                next("/auth");
            }
        }
    },
    {
        path: "/price",
        name: "price",
        breadcrumbName: "Fiyatlar",
        component: () => import("../views/Price/Home"),
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuth) {
                next();
            } else {
                next("/auth");
            }
        }
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to,from,next) => {
    if (navigator.onLine) next()
    else connectionStatus()
})

router.afterEach(to => {
    let userInfo = store.getters.authUserInfo;

    if (to.name !== "dashboard" && to.name !== "auth") {
        if (userInfo.permissions === null) router.push("/");

/* geçici çözüm olarak eklendi */
        setTimeout(() => {
            if (!userInfo.permissions[to.name]) {
                message.error("Yetkiniz olmayan alana giremezsiniz!");
                router.push("/");
            }
        }, 1000)

    }

});

export default router;
