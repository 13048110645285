import instance from "@/http/axios";
import {message} from "ant-design-vue";
import crypto from "crypto";

export const initApp = ({state, dispatch}) => {
    let token = localStorage.getItem("userToken");
    let uid = localStorage.getItem("uid");
    state.authUser.token = token;
    state.authUser.id = uid;

    return new Promise(resolve => {
        if (token != null && uid != null)
            dispatch("authUserInfo", uid).then(() => {
                return resolve({
                    dispatch,
                    loginDate: state.authUser.loginDate,
                    expireIn: state.authUser.expireIn
                });
            });
    });
};

export const auth = ({commit, state, dispatch}, payload) => {
    let formData = new FormData();
    formData.append("email", payload.email);
    formData.append("password", crypto.createHash("md5").update(payload.password).digest("hex"));
    formData.append("gaCode", payload.gaCode)
    instance.post("/user/auth", formData)
        .then(response => {
            localStorage.setItem('uid', response.data.id["$oid"])
            localStorage.setItem('userToken', response.data.token)
            message.success("Giriş başarılı yönlendiriliyorsunuz...");
            commit("authUser", response.data);
            dispatch("authUserInfo", response.data.id.$oid);

            /* token expire control */
            let expirationDate =
                (state.authUser.loginDate + +state.authUser.expireIn) * 1000; // ms
            let time = new Date().getTime(); // return ms
            dispatch("authExpireIn", expirationDate - time);
            /* token expire control */

        }).catch(error => {
        if (error.response.status === 403)
            message.error("E-Mail veya Şifreniz yanlış.");
        if (error.response.status === 401)
            message.info("Kullanıcı bilgilerini göndermeniz zorunludur");
        if (error.response.status === 400)
            message.info("Hesabınız aktif durumda değildir.");
        if (error.response.status === 406)
            message.info("Google Auth Kodunuz Yanlış");
        if (error.response.status === 500)
            message.info("API Servisi ile bağlantı kurulamadı. Lütfen tekrar deneyin.");
    });
};

export const authUserInfo = ({state, commit}, uid) => {

    let userToken = localStorage.getItem("userToken");
    let userid = localStorage.getItem("uid");

    instance.defaults.headers = {
        Authorization: "Bearer " + userToken,
        UID: userid,
        Token: userToken
    }

    if (uid !== null)
        return instance.get("/user/info?id=" + uid, {
            headers: {Authorization: "Bearer " + state.authUser.token}
        }).then(r => {
            commit("userInfoUpdate", r);
            return new Promise(resolve => resolve("user info updated"));
        }).catch(error => {
            commit("authDestroy");
        })
};

export const authDestroy = ({commit}) => {
    commit("authDestroy");
};

export const authExpireIn = ({dispatch}, expireIn) => {
    setTimeout(() => {
        message.info(
            "Oturum süreniz sona erdiği için tekrar giriş yapmanız gerekmektedir.",
            5
        );
        dispatch("authDestroy");
    }, expireIn);
};

export const notificationUpdate = ({commit}, payload) => {
    commit('notificationUpdate', payload)
}
